import { default as indexZaEsz4mwwdMeta } from "/vercel/path0/pages/[storefront]/[...slugs]/index.vue?macro=true";
import { default as addressKvK7zQvBRKMeta } from "/vercel/path0/pages/[storefront]/checkout/address.vue?macro=true";
import { default as cartwl8oSTIcwdMeta } from "/vercel/path0/pages/[storefront]/checkout/cart.vue?macro=true";
import { default as paymentnd1gGEPWNWMeta } from "/vercel/path0/pages/[storefront]/checkout/payment.vue?macro=true";
import { default as _91_91id_93_932qCA8z7lrwMeta } from "/vercel/path0/pages/[storefront]/checkout/success/[[id]].vue?macro=true";
import { default as checkout4RlX9zT5GaMeta } from "/vercel/path0/pages/[storefront]/checkout.vue?macro=true";
import { default as _91_46_46_46sku_93nFL5fjk60xMeta } from "/vercel/path0/pages/[storefront]/diagnostics/product/[...sku].vue?macro=true";
import { default as _91faq_section_93Mt7D0bKmr9Meta } from "/vercel/path0/pages/[storefront]/faq/[faq_section].vue?macro=true";
import { default as cookie_45policyUgROdmGU7JMeta } from "/vercel/path0/pages/[storefront]/faq/cookie-policy.vue?macro=true";
import { default as faqGzbmMbT5CCMeta } from "/vercel/path0/pages/[storefront]/faq.vue?macro=true";
import { default as indexaRJ8wibFjuMeta } from "/vercel/path0/pages/[storefront]/index.vue?macro=true";
import { default as loginuqP1k1SfTRMeta } from "/vercel/path0/pages/[storefront]/login.vue?macro=true";
import { default as _91sku_93fKmRS3dQBnMeta } from "/vercel/path0/pages/[storefront]/product/[sku].vue?macro=true";
import { default as _91_91pageNumber_93_93Fxq4PFAw9BMeta } from "/vercel/path0/pages/[storefront]/search/[[pageNumber]].vue?macro=true";
import { default as shared_45contentVZ821v38Y1Meta } from "/vercel/path0/pages/[storefront]/shared-content.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: "storefront-slugs",
    path: "/:storefront()/:slugs(.*)*",
    meta: indexZaEsz4mwwdMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/[...slugs]/index.vue").then(m => m.default || m)
  },
  {
    name: "storefront-checkout",
    path: "/:storefront()/checkout",
    meta: checkout4RlX9zT5GaMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/checkout.vue").then(m => m.default || m),
    children: [
  {
    name: "storefront-checkout-address",
    path: "address",
    meta: addressKvK7zQvBRKMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/checkout/address.vue").then(m => m.default || m)
  },
  {
    name: "storefront-checkout-cart",
    path: "cart",
    meta: cartwl8oSTIcwdMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "storefront-checkout-payment",
    path: "payment",
    meta: paymentnd1gGEPWNWMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: "storefront-checkout-success-id",
    path: "success/:id?",
    meta: _91_91id_93_932qCA8z7lrwMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/checkout/success/[[id]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "storefront-diagnostics-product-sku",
    path: "/:storefront()/diagnostics/product/:sku(.*)*",
    component: () => import("/vercel/path0/pages/[storefront]/diagnostics/product/[...sku].vue").then(m => m.default || m)
  },
  {
    name: "storefront-faq",
    path: "/:storefront()/faq",
    component: () => import("/vercel/path0/pages/[storefront]/faq.vue").then(m => m.default || m),
    children: [
  {
    name: "storefront-faq-faq_section",
    path: ":faq_section()",
    component: () => import("/vercel/path0/pages/[storefront]/faq/[faq_section].vue").then(m => m.default || m)
  },
  {
    name: "storefront-faq-cookie-policy",
    path: "cookie-policy",
    component: () => import("/vercel/path0/pages/[storefront]/faq/cookie-policy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "storefront",
    path: "/:storefront()",
    meta: indexaRJ8wibFjuMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/index.vue").then(m => m.default || m)
  },
  {
    name: "storefront-login",
    path: "/:storefront()/login",
    component: () => import("/vercel/path0/pages/[storefront]/login.vue").then(m => m.default || m)
  },
  {
    name: "storefront-product-sku",
    path: "/:storefront()/product/:sku()",
    meta: _91sku_93fKmRS3dQBnMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/product/[sku].vue").then(m => m.default || m)
  },
  {
    name: "storefront-search-pageNumber",
    path: "/:storefront()/search/:pageNumber?",
    meta: _91_91pageNumber_93_93Fxq4PFAw9BMeta || {},
    component: () => import("/vercel/path0/pages/[storefront]/search/[[pageNumber]].vue").then(m => m.default || m)
  },
  {
    name: "storefront-shared-content",
    path: "/:storefront()/shared-content",
    meta: shared_45contentVZ821v38Y1Meta || {},
    component: () => import("/vercel/path0/pages/[storefront]/shared-content.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]