import defu from 'defu'

import type { BannerResponse, CheckoutBannerResponse, Datasource } from 'types/storyblok'
import type { SharedContentResponse } from 'types/storyblok/shared-content'
import type { Category } from 'utils/categories/normalizeCategories'
import { isLocaleField } from '../../types/storyblok'
import { isArray } from '../../types/generic/data-type.guards'
import type { SegmentedPopupResponse } from './../../types/storyblok/shared-content/segmented-popup'
import type { HeaderNavbarResponse } from '~/types/storyblok/shared-content/navbar'
import type { NewsletterResponse } from '~/types/storyblok/shared-content/newsletter'
import type { CheckoutNewsletterCheckboxResponse } from '~/types/storyblok/shared-content/newsletter-checkbox'
import type { PdpShippingAndReturnsContent } from '~/types/storyblok/shared-content/pdp-shipping-and-returns'
import type { PdpSustainabilityContent } from '~/types/storyblok/shared-content/pdp-sustainability'
import type { SizeGuide } from '~/types/storyblok/shared-content/size-guides'
import type { SpeechBubbleResponse } from '~/types/storyblok/shared-content/speech-bubble'
import type { WidgetContent } from '~/types/storyblok/shared-content/widgets'
import type Storyblok from '~/types/vendors/storyblok'
import type { PdpFeaturesIconsResponse } from '~/types/storyblok/shared-content/pdp-features-icons'
import type { FooterSchema } from '~/types/storyblok/shared-content/footer'
import type { PdpCertifications } from '~/types/storyblok/shared-content/pdp-certifications'
import type { FacetStory } from '~/types/storyblok/shared-content/facets'
import type { SidebarFilters } from '~/types/storyblok/shared-content/sidebar-filters'
import type { SegmentContent } from '~/types/storyblok/shared-content/segment'
import type { SegmentedUpsellContent } from '~/types/storyblok/shared-content/segmented-upsell'
import type { PdpEnhancedContent } from '~/types/storyblok/shared-content/pdp-enhanced'

export async function getSharedContentDefault(
  contentKey: string,
): Promise<{ stories: SharedContentResponse['stories'], categories: Category[] }> {
  const env = useRuntimeConfig().public.environment
  const data = await import(`../../data/defaults/shared-content.${contentKey}.${env}.json`)

  if (!data) {
    const defaultC = await import(`../../data/defaults/shared-content.us.${env}.json`)

    return defaultC.default
  }

  return data.default
}

export async function getConstantsDefault() {
  const defaultConstants = await import(`../../data/defaults/constants-default.json`)

  return defaultConstants.default
}

export async function getCategoriesDefault(contentLocale: string): Promise<Category[]> {
  const defaults = await import(
    `../../data/defaults/categories_${
      isLocaleField(contentLocale) ? contentLocale : 'default'
    }.gen.json`
  )

  if (!defaults.default) {
    // return default categories
    const defaultC = await import(`../../data/defaults/categories_default.gen.json`)

    return defaultC.default
  }

  return defaults.default
}

export async function getTranslationsDefault(contentLocale: string): Promise<Datasource[]> {
  const defaults = await import(
    `../../data/defaults/translations_${
      isLocaleField(contentLocale) ? contentLocale : 'default'
    }.json`
  )

  return defaults.default
}

interface GetComponentFromStoriesOptions {
  componentName?: string
  slug?: string
  multiple?: boolean
}

function getComponentFromStories<T>(
  stories: Storyblok.Story<any>[],
  options: GetComponentFromStoriesOptions & { multiple: true }
): Storyblok.Story<T>[] | undefined
function getComponentFromStories<T>(
  stories: Storyblok.Story<any>[],
  options: GetComponentFromStoriesOptions & { multiple?: false }
): Storyblok.Story<T> | undefined
function getComponentFromStories<T>(
  stories: Storyblok.Story<any>[],
  options: GetComponentFromStoriesOptions,
): Storyblok.Story<T> | Storyblok.Story<T>[] | undefined {
  const { componentName, slug, multiple } = defu(options, {
    componentName: undefined,
    slug: undefined,
    multiple: false,
  })
  const isStoryMatcher = (story: Storyblok.Story<any>) => {
    if (componentName && slug)
      return story.content.component === componentName && story.slug === slug

    if (componentName)
      return story.content.component === componentName

    if (slug)
      return story.slug === slug

    return false
  }

  function isConditionalComponent(componentName?: string) {
    const conditionalComponents = [
      'checkout-message-banner',
    ]
    return conditionalComponents.includes(componentName ?? '')
  }

  const story = multiple ? stories.filter(isStoryMatcher) : stories.find(isStoryMatcher)

  if (!story || (isArray(story) && !story.length)) {
    if (!isConditionalComponent(componentName))
      console.warn('Storyblok: Unable to find story with component name: ', componentName)
    return undefined
  }

  return story as Storyblok.Story<T>
}

// 3. order/group stories from storyblock response
export function getSharedContentComponentsFromMultipleStoryResponse(
  stories: Storyblok.Story<any>[],
) {
  return {
    segmentedUpsellStories:
      getComponentFromStories<SegmentedUpsellContent>(stories, {
        componentName: 'segmented-upsell',
        multiple: true,
      }) ?? [],

    pdpEnhancedBlocks:
      getComponentFromStories<PdpEnhancedContent>(stories, {
        componentName: 'pdp-enhanced',
        multiple: true,
      }) ?? [],

    sizeGuides:
      getComponentFromStories<SizeGuide>(stories, {
        componentName: 'pdp-size-guide',
        multiple: true,
      }) ?? [],

    speechBubbleStates:
      getComponentFromStories<SpeechBubbleResponse>(stories, {
        componentName: 'speechBubble',
        multiple: true,
      }) ?? [],

    topBanner: getComponentFromStories<BannerResponse>(stories, {
      componentName: 'banner',
      slug: 'banner',
    }),

    newsletter: getComponentFromStories<NewsletterResponse>(stories, {
      componentName: 'newsletter',
    }),

    segmentedPopups: getComponentFromStories<SegmentedPopupResponse>(stories, {
      componentName: 'segmented-popup',
      multiple: true,
    }),

    checkoutNewsletterCheckbox: getComponentFromStories<CheckoutNewsletterCheckboxResponse>(
      stories,
      {
        componentName: 'checkout-newsletter-checkbox',
      },
    ),

    checkoutBanner: getComponentFromStories<CheckoutBannerResponse>(stories, {
      componentName: 'checkout-message-banner',
    }),

    headerNavbar: getComponentFromStories<HeaderNavbarResponse>(stories, {
      componentName: 'top-level-nav',
      slug: 'navigation',
    }),

    headerNavbarAlternate: getComponentFromStories<HeaderNavbarResponse>(stories, {
      componentName: 'top-level-nav',
      slug: 'navigation-alternate',
    }),

    pdpFeaturesIcons: getComponentFromStories<PdpFeaturesIconsResponse>(stories, {
      componentName: 'pdp-list-icons',
    }),

    pdpCertifications: getComponentFromStories<PdpCertifications>(stories, {
      componentName: 'pdp-certifications',
    }),

    pdpShippingAndReturns: getComponentFromStories<PdpShippingAndReturnsContent>(stories, {
      componentName: 'pdp-shipping-and-returns',
    }),

    pdpSustainability: getComponentFromStories<PdpSustainabilityContent>(stories, {
      componentName: 'pdp-sustainability',
    }),
    widgets:
      getComponentFromStories<WidgetContent>(stories, {
        componentName: 'widget',
        multiple: true,
      }) ?? [],
    footer: getComponentFromStories<FooterSchema>(stories, {
      componentName: 'footer',
    }),
    facets: getComponentFromStories<FacetStory>(stories, {
      componentName: 'facet',
      multiple: true,
    }),
    sidebarFilters: getComponentFromStories<SidebarFilters>(stories, {
      componentName: 'sidebar-filters',
    }),
    segments: getComponentFromStories<SegmentContent>(stories, {
      componentName: 'segment',
      multiple: true,
    }),
  }
}
